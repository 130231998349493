import React, {FC, Fragment} from 'react';
import 'devextreme/data/odata/store';
import { HeaderBox, Stops, Totals, Title } from 'components/load-confirmation'
import {AppointmentDetails, Stop} from 'types'

type Props = {
    stops: Stop[],
    appointmentDetails: AppointmentDetails,
    weight: string,
    cube: string,
    pieceCount: string,
    lineItems: string,
    loadNumber: string
}

const getStopAddress = (singleStop: any) => {
  const stopAddress = `${singleStop.address1}, ${singleStop.city}, ${singleStop.state} ${singleStop.zip}`;
  return `https://www.google.com/maps/?&t=k&q=loc:${encodeURIComponent(stopAddress)}`;
}

const AppointmentDetail: FC<Props> = ({ 
    stops, 
    weight, 
    cube, 
    pieceCount, 
    lineItems, 
    loadNumber,
    appointmentDetails  
}) => {

    var singleStop = stops[0] as any;

    return (


        <Fragment>
            <div className={`main-container-item-container`}>
                <HeaderBox headerTitle={'Appointment Details'} changed={false}/>
                <div className={"left-margins-double right-margins-double"}>
                    <Stops loadNumber={loadNumber} stops={stops}/>
                </div>
                <Title title="Totals"/>
                <div className={"left-margins-double right-margins-double"}>
                    <Totals weight={weight} cube={cube} pieceCount={pieceCount} items={lineItems}/>
                </div>
                <div className={"left-margins-double right-margins-double"}>
            <a
              rel="noopener noreferrer"
              className="preferred-route-button-button dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon dx-button-has-text"
              style={{color: 'white'}}
              target={"_blank"}
              href={getStopAddress(singleStop)}
                    >
                        <div className="dx-button-content">
                            <i className="dx-icon dx-icon-map"></i>
                            <span className="dx-button-text">Satellite View</span>
                        </div>
                    </a>
                </div>
                <br/>
            </div>
        </Fragment>
    );

};

export default AppointmentDetail