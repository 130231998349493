import { useHistory } from 'react-router-dom'
import { UserClaims, SignoutOptions } from '@okta/okta-auth-js'
import { oktaAuth } from 'components/okta/SecureRouter'

export default function () {

    const getAccessToken = async () => {
        if (oktaAuth) {
            try {
                const token: string | undefined = oktaAuth.getAccessToken();
                if (token) {
                    return token;
                }
            }
            catch (error) {
                console.log("Error trying to get access token: ", error);
            }
        }
        return null;
    }

    const history = useHistory()

    const logout = async (url: string) => {
        if (oktaAuth != null) {
            var signOutOptions: SignoutOptions = { postLogoutRedirectUri: url }
            await oktaAuth.signOut(signOutOptions);
            await oktaAuth.closeSession();
            window.location.href = url
        }
        else {
            history.push(url)
        }
    }

    //TODO We need to make it so that if a user isn't signed in, they cannot access the website
    //This should either be successful or throw an exception
    const getUser = async () => {
        if (oktaAuth != null) {
            const user: UserClaims | undefined = await oktaAuth.token.getUserInfo()
            return user;
        }
    }

    const getAuthHeader = async () => {
        let token = await oktaAuth.getAccessToken()
        return {
            Authorization: `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_KEY}`,
            'Content-Type': 'application/json',
        }
    }

    const getAuthHeaderForm = async () => {
        let token = await getAccessToken()
        return {
            Authorization: `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_KEY}`,
            // Posting from DevExtreme Datagrid requires this format for it to work with .NET.
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        }
    }

    const getAuthHeaderOnlySync = () => {
        let token = oktaAuth.getAccessToken()
        return {
            Authorization: `Bearer ${token}`,
            'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APIM_KEY}`
        }
    }

    const getUserClaim = async (claim: any) => {
        if (oktaAuth != null) {
            const user: UserClaims | undefined = await oktaAuth.token.getUserInfo()
            if (user != null) {
                return user[claim];
            }
        }
    }

    return {
        getAccessToken: getAccessToken,
        getAuthHeader: getAuthHeader,
        getUser: getUser,
        getUserClaim: getUserClaim,
        logout: logout,
        getAuthHeaderForm: getAuthHeaderForm,
        getAuthHeaderOnlySync: getAuthHeaderOnlySync
    }
}